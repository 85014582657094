"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _withScopeId = n => ((0, vue_2.pushScopeId)("data-v-2eaa768d"), n = n(), (0, vue_2.popScopeId)(), n);
const _hoisted_1 = { key: 0 };
const _hoisted_2 = ["id", "onDragstart", "data-sequence-number", "data-id"];
const _hoisted_3 = { class: "text-center text-xl font-bold w-8" };
const _hoisted_4 = { class: "font-bold" };
const _hoisted_5 = { class: "flex gap-4 justify-center" };
const _hoisted_6 = ["disabled"];
const vue_3 = require("vue");
const shipmentStore_1 = require("../../stores/shipment/shipmentStore");
const vue_router_1 = require("vue-router");
const toastStore_1 = require("../../stores/toast/toastStore");
const ShipmentStatus_1 = require("../../models/ShipmentStatus");
const i18n_1 = require("../../common/i18n");
exports.default = (0, vue_1.defineComponent)({
    __name: 'ShipmentOrdering',
    setup(__props) {
        var _a, _b, _c, _d, _e;
        const shipmentStore = (0, shipmentStore_1.useShipmentStore)();
        let draggingId = (0, vue_3.ref)(0);
        const needToSave = (0, vue_3.ref)(false);
        const router = (0, vue_router_1.useRouter)();
        const toastStore = (0, toastStore_1.useToastStore)();
        const packingSlipListFields = (0, vue_3.ref)([
            {
                key: "sequenceNumber",
                label: (_a = (0, i18n_1.trans)('sequence_number', "Sequence", 'shipment')) !== null && _a !== void 0 ? _a : "Sequence",
                value: "sequenceNumber",
            },
            {
                key: "id",
                label: (_b = (0, i18n_1.trans)('id', "ID", 'shipment')) !== null && _b !== void 0 ? _b : "ID",
                value: "id",
            },
            {
                key: "customerName",
                label: (_c = (0, i18n_1.trans)('customer_name', "Customer Name", 'shipment')) !== null && _c !== void 0 ? _c : "Customer Name",
                value: "customerName",
            },
            {
                key: "customerAddress",
                label: (_d = (0, i18n_1.trans)('customer_address', "Customer Address", 'shipment')) !== null && _d !== void 0 ? _d : "Customer Address",
                value: "customerAddress",
            },
            {
                key: "cashOnDeliveryPrice",
                label: (_e = (0, i18n_1.trans)('cash_on_delivery_price', "Cash on delivery price", 'shipment')) !== null && _e !== void 0 ? _e : "Cash on delivery price",
                value: "cashOnDeliveryPrice",
            },
        ]);
        (0, vue_3.onBeforeMount)(async () => {
            await shipmentStore.getStopPointsByShipment(router, { shipmentId: shipmentStore.shipment.id });
            // le kell ellenorizni a sorrendiseget, hogy mindenhol jo ertekek legyenek
            // tehat ne legyen sehol 0, es ne legyen 2 egyforma szam sem
            // sorbarendezzuk, igy biztosan tudhatjuk, hogy a nulla az elso
            let stopPointsList = shipmentStore.orderedStopPoints;
            let stopPointsListUnordered = [];
            let stopPointsListReordered = [];
            let lastSequenceNumber = 0;
            stopPointsList.forEach((item) => {
                if (0 === item.sequenceNumber) {
                    // egy rendetlen elemet talaltunk
                    stopPointsListUnordered.push(item);
                }
                else {
                    // van mar sequence number
                    // berakjuk az ujrarendezett listaba
                    lastSequenceNumber++;
                    if (item.sequenceNumber !== lastSequenceNumber) {
                        // mentes szukseges
                        needToSave.value = true;
                    }
                    item.sequenceNumber = lastSequenceNumber;
                    stopPointsListReordered.push(item);
                }
            });
            if (stopPointsListUnordered.length) {
                // mentes szukseges
                needToSave.value = true;
            }
            let stopPoint;
            // a rendezetlen elemeket az ujrarendezett listahoz adjuk
            while (stopPointsListUnordered.length) {
                stopPoint = stopPointsListUnordered.pop();
                lastSequenceNumber++;
                stopPoint.sequenceNumber = lastSequenceNumber;
                stopPointsListReordered.push(stopPoint);
            }
            shipmentStore.stopPoints = stopPointsListReordered;
        });
        const onStopPointDragStart = (event, stopPoint) => {
            event.dataTransfer.dropEffect = 'move';
            event.dataTransfer.effectAllowed = 'move';
            event.dataTransfer.setData('stopPointId', stopPoint.id);
            event.dataTransfer.setData('stopPointSequenceNumber', stopPoint.sequenceNumber);
            draggingId.value = stopPoint.id;
        };
        const onStopPointDrop = (event) => {
            if (!event.target) {
                return;
            }
            let id = event.dataTransfer.getData('stopPointId');
            let sequenceNumber = event.dataTransfer.getData('stopPointSequenceNumber');
            let mouseY = event.clientY;
            // let id = event.dataTransfer.getData('packingSlipId');
            // let sequenceNumber = event.dataTransfer.getData('packingSlipSequenceNumber');
            // draggingId.value = 0;
            // meg kell allapitani, hogy ledobas helye hol van
            // az eger Y pozicioja event.clientY
            // meg kell allapitani, hogy melyik elemhez van a legkozelebb
            event.target.childNodes.forEach((item) => {
                if (item.dataset) {
                    let box = item.getBoundingClientRect();
                    // ha a bottom > mint a mouse, akkor kell figyelni
                    // ha a top
                }
            });
        };
        const onStopPointDropToItem = (event) => {
            if (!event.target) {
                return;
            }
            let id = event.dataTransfer.getData('stopPointId');
            let sequenceNumber = event.dataTransfer.getData('stopPointSequenceNumber');
            // meg kell keresni a target-et
            let target = event.target;
            while (target) {
                if (target.classList.contains('drop-zone')) {
                    break;
                }
                target = target.parentNode;
            }
            if (!target) {
                return;
            }
            let catcherId = target.dataset.id;
            if (id == catcherId) {
                // sajat magara dobtuk, nem kell semmit se csinalni
                return;
            }
            needToSave.value = true;
            let catcherSequenceNumber = parseInt(target.dataset.sequenceNumber);
            let box = target.getBoundingClientRect();
            draggingId.value = 0;
            // meg kell allapitani, hogy ledobas helye hol van
            // az eger Y pozicioja event.clientY
            let mouseY = event.clientY;
            let tresholdY = box.top + (box.height / 2);
            let newSequenceNumber = catcherSequenceNumber;
            if (mouseY > tresholdY) {
                // az also reszben vagyunk, tehat moge kerul
                newSequenceNumber = catcherSequenceNumber + 1;
            }
            else if (mouseY < tresholdY) {
                // a felso reszben vagyunk, tehat ele kerul
                // pass
            }
            shipmentStore.reOrderingStopPoint(sequenceNumber - 1, newSequenceNumber - 1);
        };
        const shipmentOrderingFinished = (finished = false) => {
            let ordering = {};
            shipmentStore.stopPoints.forEach((stopPoint) => {
                const { id, sequenceNumber } = stopPoint;
                ordering[id] = sequenceNumber;
            });
            shipmentStore.apiShipmentOrderingFinished(router, shipmentStore.shipment.id, ordering, finished)
                .then((response) => {
                let shipment = response;
                shipmentStore.replaceShipment(shipment);
                if (ShipmentStatus_1.ShipmentStatus.READY == shipment.status) {
                    toastStore.addToast({
                        message: (0, i18n_1.trans)('shipment_ordering_finished', 'Shipment ordering finished.', 'shipment'),
                        icon: 'marso-icon-notification',
                        type: 'success'
                    });
                }
                else if (ShipmentStatus_1.ShipmentStatus.PREPARED == shipment.status) {
                    toastStore.addToast({
                        message: (0, i18n_1.trans)('shipment_ordering_saved', 'Shipment ordering saved.', 'shipment'),
                        icon: 'marso-icon-notification',
                        type: 'success'
                    });
                }
            })
                .catch((error) => {
                toastStore.addToast({
                    message: (0, i18n_1.trans)('shipment_ordering_failed', 'Shipment ordering failed!', 'shipment'),
                    icon: 'marso-icon-notification',
                    type: 'danger'
                });
            });
        };
        const getPackingSlipIds = (deliveryAddress) => {
            if (!shipmentStore.shipment.packingSlips.length || !deliveryAddress)
                return;
            const ids = shipmentStore.shipment.packingSlips
                .filter(packingSlip => {
                const address = `${packingSlip.deliveryZipCode} ${packingSlip.deliveryCity} ${packingSlip.deliveryStreet}`;
                return deliveryAddress === address;
            })
                .map(packingSlip => packingSlip.id);
            return ids.join(', ');
        };
        (0, vue_3.onUnmounted)(() => {
            shipmentStore.stopPoints = [];
        });
        return (_ctx, _cache) => {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)(vue_2.Fragment, null, [
                ('PREPARED' == (0, vue_2.unref)(shipmentStore).shipment.status)
                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                        (0, vue_2.createVNode)(vue_2.TransitionGroup, {
                            name: "stop-point",
                            tag: "div",
                            class: "drop-zone flex flex-col gap-4 pb-4 pt-4",
                            onDrop: _cache[1] || (_cache[1] = ($event) => (onStopPointDrop($event))),
                            onDragenter: _cache[2] || (_cache[2] = (0, vue_2.withModifiers)(() => { }, ["prevent"])),
                            onDragover: _cache[3] || (_cache[3] = (0, vue_2.withModifiers)(() => { }, ["prevent"]))
                        }, {
                            default: (0, vue_2.withCtx)(() => [
                                ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)((0, vue_2.unref)(shipmentStore).orderedStopPoints, (stopPoint) => {
                                    return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", {
                                        id: stopPoint.id,
                                        class: (0, vue_2.normalizeClass)(["drop-zone grid grid-cols-[auto,1fr,1fr] bg-white p-4 gap-4 cursor-grab", (0, vue_2.unref)(draggingId) == stopPoint.id ? 'dragging shadow-sm' : '']),
                                        draggable: "true",
                                        onDragstart: ($event) => (onStopPointDragStart($event, stopPoint)),
                                        onDrop: _cache[0] || (_cache[0] = (0, vue_2.withModifiers)(($event) => (onStopPointDropToItem($event)), ["stop", "prevent"])),
                                        "data-sequence-number": stopPoint.sequenceNumber,
                                        "data-id": stopPoint.id,
                                        key: stopPoint.id
                                    }, [
                                        (0, vue_2.createElementVNode)("div", _hoisted_3, (0, vue_2.toDisplayString)(stopPoint.sequenceNumber), 1),
                                        (0, vue_2.createElementVNode)("div", _hoisted_4, (0, vue_2.toDisplayString)(stopPoint.deliveryAddress), 1),
                                        (0, vue_2.createElementVNode)("div", null, (0, vue_2.toDisplayString)(getPackingSlipIds(stopPoint.deliveryAddress)), 1)
                                    ], 42, _hoisted_2));
                                }), 128))
                            ]),
                            _: 1
                        })
                    ]))
                    : (0, vue_2.createCommentVNode)("", true),
                (0, vue_2.createElementVNode)("div", _hoisted_5, [
                    ('PREPARED' == (0, vue_2.unref)(shipmentStore).shipment.status)
                        ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("button", {
                            key: 0,
                            type: "button",
                            class: "btn btn-secondary w-auto md:h-full",
                            disabled: !needToSave.value,
                            onClick: _cache[4] || (_cache[4] = ($event) => (shipmentOrderingFinished()))
                        }, (0, vue_2.toDisplayString)((0, vue_2.unref)(i18n_1.trans)('shipment_ordering_save_button', 'Save the order', 'shipment')), 9, _hoisted_6))
                        : (0, vue_2.createCommentVNode)("", true),
                    ('PREPARED' == (0, vue_2.unref)(shipmentStore).shipment.status)
                        ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("button", {
                            key: 1,
                            type: "button",
                            class: "btn btn-success w-auto md:h-full",
                            onClick: _cache[5] || (_cache[5] = ($event) => (shipmentOrderingFinished(true)))
                        }, (0, vue_2.toDisplayString)((0, vue_2.unref)(i18n_1.trans)('shipment_ordering_finished_button', 'Ordering finished', 'shipment')), 1))
                        : (0, vue_2.createCommentVNode)("", true)
                ])
            ], 64));
        };
    }
});
