"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// lasd: /api
exports.default = [
    {
        name: 'api_axapta_get_packing_slips',
        path: '/api/axapta/get-packing-slips',
        redirect: '',
        meta: { requiresAuth: true }
    },
    {
        name: 'api_shipments',
        path: '/api/shipments',
        redirect: '',
        meta: { requiresAuth: true }
    },
    {
        name: 'api_shipment',
        path: '/api/shipments/:id',
        redirect: '',
        meta: { requiresAuth: true }
    },
    {
        name: 'api_shipments_create_from_axapta_packing_slips',
        path: '/api/shipments/create-from-axapta-packing-slips',
        redirect: '',
        meta: { requiresAuth: true }
    },
    {
        name: 'api_shipments_add_from_axapta_packing_slips',
        path: '/api/shipments/:id/add-from-axapta-packing-slips',
        redirect: '',
        meta: { requiresAuth: true }
    },
    {
        name: 'api_shipments_return_to_warehouse',
        path: '/api/shipments/:id/return-to-warehouse',
        redirect: '',
        meta: { requiresAuth: true }
    },
    {
        name: 'api_shipments_delivery_finished',
        path: '/api/shipments/:id/delivery-finished',
        redirect: '',
        meta: { requiresAuth: true }
    },
    {
        name: 'api_packing_slips',
        path: '/api/packing-slips',
        redirect: '',
        meta: { requiresAuth: true }
    },
    {
        name: 'api_packing_slip',
        path: '/api/packing-slips/:id',
        redirect: '',
        meta: { requiresAuth: true }
    },
    {
        name: 'api_shipments_preparing_finished',
        path: '/api/shipments/:id/preparing-finished',
        redirect: '',
        meta: { requiresAuth: true }
    },
    {
        name: 'api_shipments_ordering_finished',
        path: '/api/shipments/:id/ordering-finished',
        redirect: '',
        meta: { requiresAuth: true }
    },
    {
        name: 'api_shipments_delivery_starting',
        path: '/api/shipments/:id/delivery-starting',
        redirect: '',
        meta: { requiresAuth: true }
    },
    {
        name: 'api_packing_slip_delivery_done',
        path: '/api/packing-slips/:id/delivery-done',
        redirect: '',
        mate: { requiresAuth: true }
    },
    {
        name: 'api_packing_slip_warehouse_done',
        path: '/api/packing-slips/:id/warehouse-done',
        redirect: '',
        mate: { requiresAuth: true }
    },
    {
        name: 'api_packing_slip_items',
        path: '/api/packing-slip-items',
        redirect: '',
        meta: { requiresAuth: true }
    },
    {
        name: 'api_packing_slip_item',
        path: '/api/packing-slip-items/:id',
        redirect: '',
        meta: { requiresAuth: true }
    },
    {
        name: 'api_packing_slip_images',
        path: '/api/packing-slip-images',
        redirect: '',
        meta: { requiresAuth: true }
    },
    {
        name: 'api_packing_slip_image',
        path: '/api/packing-slip-images/view/:id',
        redirect: '',
        meta: { requiresAuth: true }
    },
    {
        name: 'api_drivers',
        path: '/api/drivers',
        redirect: '',
        meta: { requiresAuth: true }
    },
    {
        name: 'api_trucks',
        path: '/api/trucks',
        redirect: '',
        meta: { requiresAuth: true }
    },
    {
        name: 'api_axapta_shipment_send_sms',
        path: '/api/axapta/shipment-send-sms',
        redirect: '',
        meta: { requiresAuth: true }
    },
    {
        name: 'api_drivers',
        path: '/api/drivers',
        redirect: '',
        meta: { requiresAuth: true }
    },
    {
        name: 'api_stop_points_by_shipment',
        path: '/api/stop-points-by-shipment',
        redirect: '',
        meta: { requiresAuth: true }
    },
    {
        name: 'api_packing_slip_delete',
        path: '/api/packing-slips/:id/delete',
        redirect: '',
        meta: { requiresAuth: true }
    }
];
