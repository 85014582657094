"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _hoisted_1 = { class: "flex flex-col w-full justify-center" };
const _hoisted_2 = {
    key: 0,
    class: "block pb-2 font-bold uppercase tracking-wide text-sm"
};
const _hoisted_3 = ["value", "disabled"];
const _hoisted_4 = ["selected"];
const _hoisted_5 = ["selected", "value"];
const _hoisted_6 = {
    key: 0,
    class: "text-red mt-2 text-sm"
};
exports.default = (0, vue_1.defineComponent)({
    __name: 'SelectInput',
    props: {
        label: {
            type: String,
            required: false,
            default: "",
        },
        options: {
            type: Array,
            required: true,
            default: () => []
        },
        modelValue: [Number, String, () => null],
        placeholderEnable: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: ''
        },
        classes: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        showValidity: {
            type: Boolean,
            default: false,
        },
        valid: {
            type: Boolean,
            default: true,
            required: false
        },
        invalidMessage: {
            type: String,
            required: false,
            default: ''
        }
    },
    emits: ["update:modelValue"],
    setup(__props, { emit }) {
        const props = __props;
        const selectOption = (event) => {
            emit('update:modelValue', event.target.value);
        };
        return (_ctx, _cache) => {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", null, [
                (0, vue_2.createElementVNode)("label", _hoisted_1, [
                    (__props.label)
                        ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("span", _hoisted_2, (0, vue_2.toDisplayString)(__props.label), 1))
                        : (0, vue_2.createCommentVNode)("", true),
                    (0, vue_2.createElementVNode)("select", {
                        onChange: _cache[0] || (_cache[0] = ($event) => (selectOption($event))),
                        value: __props.modelValue,
                        disabled: __props.disabled,
                        class: (0, vue_2.normalizeClass)(["block py-2 px-2 w-full placeholder-gray-dark text-lg outline-none transition-all duration-300 disabled:bg-gray border border-gray-dark border-opacity-50 focus:border-blue focus:ring-4 focus:ring-blue focus:ring-opacity-50 bg-white text-[15px] h-[50px]", {
                                [__props.classes]: true,
                                'outline-2 outline-green': __props.valid && __props.showValidity,
                                'outline-2 outline-red': !__props.valid && __props.showValidity
                            }])
                    }, [
                        (__props.placeholderEnable)
                            ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("option", {
                                key: 0,
                                disabled: "",
                                selected: __props.modelValue === null || __props.modelValue === ''
                            }, (0, vue_2.toDisplayString)(__props.placeholder), 9, _hoisted_4))
                            : (0, vue_2.createCommentVNode)("", true),
                        ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)(__props.options, (option) => {
                            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("option", {
                                key: option.value,
                                selected: option.selected,
                                value: option.value
                            }, (0, vue_2.toDisplayString)(option.text), 9, _hoisted_5));
                        }), 128))
                    ], 42, _hoisted_3)
                ]),
                (__props.showValidity && __props.invalidMessage !== '' && !__props.valid)
                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_6, (0, vue_2.toDisplayString)(__props.invalidMessage), 1))
                    : (0, vue_2.createCommentVNode)("", true)
            ]));
        };
    }
});
