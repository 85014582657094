"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _hoisted_1 = { class: "relative flex items-center justify-center gap-1" };
const _hoisted_2 = ["disabled"];
const _hoisted_3 = ["disabled"];
const i18n_1 = require("../../common/i18n");
exports.default = (0, vue_1.defineComponent)({
    __name: 'DualActionButton',
    props: {
        onPrimaryAction: { type: Function },
        onSecondaryAction: { type: Function },
        isPrimaryDisabled: { type: Boolean },
        isSecondaryDisabled: { type: Boolean },
        primaryIcon: null,
        secondaryIcon: null
    },
    setup(__props) {
        return (_ctx, _cache) => {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (0, vue_2.createElementVNode)("button", {
                    class: "btn btn-primary w-32 !h-16 !rounded-r-none",
                    disabled: __props.isPrimaryDisabled,
                    onClick: _cache[0] || (_cache[0] =
                        //@ts-ignore
                        (...args) => (__props.onPrimaryAction && __props.onPrimaryAction(...args)))
                }, [
                    (0, vue_2.createElementVNode)("i", {
                        class: (0, vue_2.normalizeClass)([__props.primaryIcon || 'marso-icon-file-signature-solid', "text-4xl"])
                    }, null, 2)
                ], 8, _hoisted_2),
                (0, vue_2.createElementVNode)("div", {
                    class: (0, vue_2.normalizeClass)(["absolute flex items-center justify-center w-14 h-14 rounded-full bg-white z-20 font-bold", __props.isSecondaryDisabled || __props.isPrimaryDisabled ? 'border border-gray-dark' : ''])
                }, (0, vue_2.toDisplayString)((0, vue_2.unref)(i18n_1.trans)('or', 'Or', 'dual_action_button')), 3),
                (0, vue_2.createElementVNode)("button", {
                    class: "btn btn-primary w-32 !h-16 !rounded-l-none",
                    disabled: __props.isSecondaryDisabled,
                    onClick: _cache[1] || (_cache[1] =
                        //@ts-ignore
                        (...args) => (__props.onSecondaryAction && __props.onSecondaryAction(...args)))
                }, [
                    (0, vue_2.createElementVNode)("i", {
                        class: (0, vue_2.normalizeClass)([__props.secondaryIcon || 'marso-icon-camera', "text-4xl"])
                    }, null, 2)
                ], 8, _hoisted_3)
            ]));
        };
    }
});
